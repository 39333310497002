import React, { createContext, useRef } from "react";
import axios from "axios";

export const AppContext = createContext();

function AppProvider({ children, railsContext }) {
  let config = {
    baseURL: railsContext?.apiUrl || "https://www.mindsumo.com/api/v1/",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    withCredentials: true,
    timeout: 15000,
  };

  if (railsContext?.railsEnv === "production") config.timeout = 10000;

  const axiosRef = useRef(axios.create({ ...config }));

  return (
    <AppContext.Provider value={{ railsContext, axios: axiosRef.current }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
