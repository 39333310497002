import React, { createContext } from "react";

export const CurrentUserContext = createContext();

function CurrentUserProvider({ children, currentUser }) {
  return (
    <CurrentUserContext.Provider value={currentUser}>
      {children}
    </CurrentUserContext.Provider>
  );
}

export default CurrentUserProvider;
