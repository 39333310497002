import media from "./media";
import constants from "./constants";
import fontStyles from "./fontStyles";

const theme = {
  ...constants,
  fontStyles: { ...fontStyles },
  media,
};

export default theme;
