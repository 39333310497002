import { css } from "styled-components";

const fontStyles = {
  DesktopCopySmallBold: css`
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  `,
  InterfaceCopyXSmall: css`
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
  `,
  MobileHeadlinesH3: css`
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  `,
  DesktopHeadlinesH3: css`
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  `,
  DesktopLargeBodyCopy: css`
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
  InterfaceCopy: css`
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  `,
  InterfaceCopySmall: css`
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  `,
  InterfaceCopyLarge: css`
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.16px;
  `,
  InterfaceCopyBold: css`
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
  `,
  CommentsBody: css`
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
  DesktopHeadlinesH5: css`
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  `,
  DesktopCopyBodyCopySmall: css`
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  `,
  DesktopCopyLargeBodyCopyBold: css`
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  `,
};

export default fontStyles;
