import { css } from "styled-components";

export const media = {
  onlySmallMobile: "(max-width: 576px)",
  onlyMobile: "(max-width: 768px)",
  onlyTablet: "(min-width: 768px) and (max-width: 1060px)",
  onlySmallDesktop: "(min-width: 1060px) and (max-width: 1440px)",
  onlyDesktop: "(min-width: 1440px)",
  exceptSmallMobile: "(min-width: 576px)",
  exceptMobile: "(min-width: 768px)",
  exceptTablet: "(max-width: 768px), (min-width: 1060px)",
  exceptSmallDesktop: "(max-width: 1060px), (min-width: 1440px)",
  exceptDesktop: "(max-width: 1440px)",
};

export default Object.keys(media).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media ${media[label]} {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
