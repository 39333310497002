import React, { lazy } from "react";
import withTheme from "../hoc/withTheme";

import ContestSettingsProvider from "../context/contestSettings";

const ChallengeShow = lazy(() => import("./challengeShow"));

const ChallengeShowClientWrapper = ({ data }) => {
  return (
    <>
      <ContestSettingsProvider value={data}>
        <ChallengeShow />
      </ContestSettingsProvider>
    </>
  );
};

export default withTheme(ChallengeShowClientWrapper);
