import React, { createContext, useState, useRef, useCallback } from "react";
import useAppContext from "../hooks/useAppContext";
import bareAxios from "axios";

export const ContestSettingsContext = createContext();

function ContestSettingsProvider({ children, value }) {
  const { axios } = useAppContext();
  const [eligibility, setEligibility] = useState(value.eligibilityStatus);
  const [eligibilityReason, setEligibilityReason] = useState(
    value.eligibilityReason,
  );
  const [formObject, setFormObject] = useState(null);
  const [currentFileUploadParams, setCurrentFileUploadParams] = useState(null);
  const [formDisabled, setFormDisabled] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const { solutionFormPath, slug, reserveSpotPath, permitQueryString } = value;
  const formRef = useRef(null);

  const getFormObject = useCallback(() => {
    if (!solutionFormPath) return;
    axios
      .get(solutionFormPath + permitQueryString)
      .then((res) => {
        setFormObject(res.data.data);
        setCurrentFileUploadParams(res.data.data.fileSignedUploadUrl);
      })
      .catch((err) => {
        setEligibility(false);
        setEligibilityReason(err.response.data.data.errors);
      });
  }, [solutionFormPath]);

  const getSignedFileParams = useCallback(() => {
    axios.get("/solutionfiles/signed_url").then((res) => {
      setCurrentFileUploadParams(res.data);
    });
  }, []);

  const handleFileUpload = useCallback(
    (file, callback) => {
      const formData = new FormData();
      formData.append("key", currentFileUploadParams.key);
      formData.append("acl", currentFileUploadParams.acl);
      formData.append("AWSAccessKeyId", currentFileUploadParams.aWSAccessKeyId);
      formData.append("policy", currentFileUploadParams.policy);
      formData.append("signature", currentFileUploadParams.signature);
      formData.append(
        "success_action_status",
        currentFileUploadParams.successActionStatus,
      );
      formData.append(
        "X-Requested-With",
        currentFileUploadParams["x-Requested-With"],
      );
      formData.append("utf8", currentFileUploadParams.utf8);
      formData.append("Content-Type", "");
      formData.append("content-type", file.type);
      formData.append("file", file);

      bareAxios({
        url: currentFileUploadParams.url,
        method: currentFileUploadParams.method,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      }).then((res) => {
        console.log(res);
        if (callback) {
          callback(res.data);
        }
        getSignedFileParams();
      });
    },
    [currentFileUploadParams],
  );

  const handleDeleteFileCallback = useCallback((key, callback) => {
    axios
      .post("/solutionfiles/deleted_callback", {
        solutionfile: {
          key: key,
        },
      })
      .then(() => {
        if (callback) {
          callback();
        }
      });
  });

  const handleAddFileCallback = useCallback((key, callback) => {
    axios
      .post("/solutionfiles/uploaded_callback", {
        solutionfile: {
          key: key,
        },
      })
      .then((res) => {
        if (callback) {
          callback(res.data.data);
        }
      });
  });

  const submitForm = useCallback(
    () =>
      formRef?.current?.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true }),
      ),
    [formRef],
  );
  const handleFormSubmit = useCallback(
    (data) => {
      setFormObject(null);
      const isPrefilter = formObject?.formType === "prefilter_form";
      const url = isPrefilter
        ? `/contests/${slug}/check_prefilter${permitQueryString}`
        : `/solutions${permitQueryString}`;
      axios
        .post(url, {
          ...data,
        })
        .then((res) => {
          if (isPrefilter) {
            setFormObject(res.data.data);
            setCurrentFileUploadParams(res.data.data.fileSignedUploadUrl);
          } else {
            window.location =
              res.data.data.viewUrl ?? res.data.data.contest.url;
          }
        })
        .catch((err) => {
          setEligibility(false);
          setEligibilityReason(err.response.data.data.errors);
          setFormObject(null);
        });
    },
    [slug, formObject],
  );
  const handleSaveDraft = useCallback((data) => {
    axios.post(`/solution_drafts${permitQueryString}`, {
      ...data,
    });
  }, []);
  const handleReserveSpot = useCallback(
    (onSuccessCallback = undefined, onFailureCallback = undefined) => {
      if (!reserveSpotPath) return;
      axios
        .post(reserveSpotPath)
        .then((res) => {
          if (res.data.data) {
            getFormObject();
            if (onSuccessCallback) onSuccessCallback(res.data.data);
            setEligibility(res.data.data.eligibilityStatus);
            setEligibilityReason(res.data.data.eligibilityReason);
          }
        })
        .catch(() => {
          if (onFailureCallback) onFailureCallback();
        });
    },
    [reserveSpotPath],
  );

  return (
    <ContestSettingsContext.Provider
      value={{
        data: value,
        formObject: formObject,
        getFormObject,
        eligibility: eligibility,
        setEligibility: setEligibility,
        eligibilityReason: eligibilityReason,
        setEligibilityReason: setEligibilityReason,
        formRef: formRef,
        submitForm: submitForm,
        handleFormSubmit: handleFormSubmit,
        handleSaveDraft: handleSaveDraft,
        handleFileUpload: handleFileUpload,
        handleDeleteFileCallback: handleDeleteFileCallback,
        handleAddFileCallback: handleAddFileCallback,
        formDisabled: formDisabled,
        setFormDisabled: setFormDisabled,
        formValid: formValid,
        setFormValid: setFormValid,
        handleReserveSpot: handleReserveSpot,
      }}
    >
      {children}
    </ContestSettingsContext.Provider>
  );
}

export default ContestSettingsProvider;
