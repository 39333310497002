import React from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";

import theme from "../theme/theme";
import CurrentUserProvider from "../context/currentUser";
import AppProvider from "../context/appContext";

const GlobalStyle = createGlobalStyle`
.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
  all: unset;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 1px;
  padding: 8.5px 14px;
}
`;

const LayoutContainer = styled.div``;

// eslint-disable-next-line react/display-name
const WithTheme = (OriginalComponent) => (props, railsContext) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <LayoutContainer>
        <AppProvider railsContext={railsContext || props.railsContext}>
          <CurrentUserProvider
            currentUser={
              railsContext?.currentUser || props.railsContext?.currentUser
            }
          >
            <OriginalComponent {...props} />
          </CurrentUserProvider>
        </AppProvider>
      </LayoutContainer>
    </ThemeProvider>
  );
};

export default WithTheme;
